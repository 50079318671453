import React from 'react';
import { Hero, Statements } from './components';

const WhistleblowingRegulation = () => (
  <>
    <Hero />
    <Statements />
  </>
);

export default WhistleblowingRegulation;
